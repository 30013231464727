<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalCreateSupplier"
      @click:outside="executeCloseModalCreateSupplier"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            {{
              currentSupplier
                ? "Actualizar Proveedor"
                : "Agregar Nuevo Proveedor"
            }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-row align-content="center" justify="start">
              <v-col cols="12" md="6" sm="12">
                <v-checkbox
                  :value="true"
                  :input-value="showAllForm"
                  @change="showAllForm = !showAllForm"
                  class="mt-4"
                >
                  <template v-slot:label>
                    <h6 class="mt-2 mx-1 mb-0">
                      Proveedor Normal
                    </h6>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-checkbox
                  :value="false"
                  :input-value="!showAllForm"
                  @change="showAllForm = !showAllForm"
                  class="mt-4"
                >
                  <template v-slot:label>
                    <h6 class="mt-2 mx-1 mb-0">
                      Proveedor de Rendiciones
                    </h6>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-form @submit.prevent="sendAddSupplier">
              <v-row align-content="center">
                <h4 class="my-3">
                  Información de la empresa
                </h4>
                <hr />
                <v-col cols="12" md="12" sm="12">
                  <label for="reason_name">
                    Razón Social *
                  </label>
                  <v-text-field
                    placeholder="Ingresa la Razón Social del Proveedor"
                    v-model="$v.form.reason_name.$model"
                    :error="$v.form.reason_name.$invalid && submitUpload"
                    :error-messages="
                      $v.form.reason_name.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0">
                  <label class="mt-1">
                    Giro del Proveedor *
                  </label>
                  <v-select
                    placeholder="Ingresa el giro del proveedor"
                    v-model="$v.form.supplier_draft.$model"
                    :error="$v.form.supplier_draft.$invalid && submitUpload"
                    :error-messages="
                      $v.form.supplier_draft.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allSupplierDrafts"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0">
                  <label for="supplier_rut" class="mt-1">
                    Rut del Proveedor *
                  </label>
                  <v-text-field
                    placeholder="Ingrese el RUT sin punto y con guión"
                    v-model="form.supplier_rut"
                    :error="$v.form.supplier_rut.$invalid && submitUpload"
                    :error-messages="
                      $v.form.supplier_rut.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="company_contact">
                    Contacto en la Empresa *
                  </label>
                  <v-text-field
                    placeholder="Ingresa el contacto de la empresa"
                    v-model="form.company_contact"
                    :error="$v.form.company_contact.$invalid && submitUpload"
                    :error-messages="
                      $v.form.company_contact.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="company_phone">
                    Teléfono del Contacto *
                  </label>
                  <v-text-field
                    placeholder="Ingresa el teléfono del contacto"
                    v-model="form.company_phone"
                    :error="$v.form.company_phone.$invalid && submitUpload"
                    :error-messages="
                      $v.form.company_phone.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="company_email">
                    Correo del Contacto *
                  </label>
                  <v-text-field
                    placeholder="Ingresa el correo del contacto"
                    v-model="form.company_email"
                    :error="$v.form.company_email.$invalid && submitUpload"
                    :error-messages="
                      $v.form.company_email.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="showAllForm">
                  <label for="address">
                    Dirección *
                  </label>
                  <v-textarea
                    rows="3"
                    counter
                    placeholder="Ingresa la Dirección del Proveedor"
                    v-model="$v.form.address.$model"
                    :error="$v.form.address.$invalid && submitUpload"
                    :error-messages="
                      $v.form.address.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="region">
                    Región *
                  </label>
                  <v-select
                    placeholder="Selecciona la región de la empresa"
                    v-model="$v.form.region.$model"
                    :error="$v.form.region.$invalid && submitUpload"
                    :error-messages="
                      $v.form.region.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="wrappedRegions"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="commune">
                    Comuna *
                  </label>
                  <v-select
                    placeholder="Selecciona la comuna de la empresa"
                    :disabled="!form.region"
                    v-model="$v.form.commune.$model"
                    :error="$v.form.commune.$invalid && submitUpload"
                    :error-messages="
                      $v.form.commune.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="communes"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="city">
                    Ciudad *
                  </label>
                  <v-text-field
                    placeholder="Ingresa la ciudad"
                    v-model="form.city"
                    :error="$v.form.city.$invalid && submitUpload"
                    :error-messages="
                      $v.form.city.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="services">
                    Servicios que entrega el Proveedor *
                  </label>
                  <v-text-field
                    placeholder="Ingresa los servicios que entrega el proveedor"
                    v-model="form.services"
                    :error="$v.form.services.$invalid && submitUpload"
                    :error-messages="
                      $v.form.services.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <h4 class="mb-3 mt-5" v-if="showAllForm">
                  Información Bancaria
                </h4>
                <hr v-if="showAllForm" />
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-3"
                  v-if="showAllForm"
                >
                  <label for="bank">
                    Banco a Depositar *
                  </label>
                  <v-select
                    placeholder="Selecciona el banco a depositar"
                    v-model="$v.form.bank.$model"
                    :error="$v.form.bank.$invalid && submitUpload"
                    :error-messages="
                      $v.form.bank.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allBanks"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label class="mt-1">
                    Boleta / Factura
                  </label>
                  <v-select
                    placeholder="Selecciona una opción"
                    v-model="$v.form.type_document_payment.$model"
                    :items="allTypesDocumentPayment"
                    item-text="name"
                    item-value="name"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="payment_method">
                    Modo de Pago
                  </label>
                  <v-select
                    placeholder="Seleccia el modo de pago"
                    v-model="$v.form.payment_method.$model"
                    :items="allPaymentMethods"
                    item-text="name"
                    item-value="name"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="account_number">
                    Numero de Cuenta *
                  </label>
                  <v-text-field
                    placeholder="Ingresa los servicios que entrega el proveedor"
                    v-model="form.account_number"
                    :error="$v.form.account_number.$invalid && submitUpload"
                    :error-messages="
                      $v.form.account_number.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0" v-if="showAllForm">
                  <label for="payment_condition">
                    Condición de Pago *
                  </label>
                  <v-select
                    placeholder="Selecciona la condición de pago"
                    v-model="$v.form.payment_condition.$model"
                    :error="$v.form.payment_condition.$invalid && submitUpload"
                    :error-messages="
                      $v.form.payment_condition.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="allPaymentConditions"
                    item-text="name"
                    item-value="name"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-0"
                  v-if="showAllForm"
                >
                  <label for="user">
                    Carga de archivos *
                    <br />
                    <small class="font-weight-bold">
                      Los siguientes archivos son necesarios para crear un
                      proveedor (Adjuntar en formato PDF)
                    </small>
                  </label>
                  <br />
                  <ul>
                    <li>
                      1. Declaración de vinculo contratista, proveedor de bienes
                      y/o servicios
                    </li>
                    <li>
                      2. Anexo de cumplimiento de prevención del delito firmado
                    </li>
                    <li>
                      3. Carta de transferencia bancaria notariada
                    </li>
                    <li>
                      4. Instructivo formato
                    </li>
                    <li>
                      5. Condiciones generales
                    </li>
                  </ul>
                  <v-file-input
                    accept="application/pdf"
                    multiple
                    counter
                    show-size
                    chips
                    deletable-chips
                    placeholder="Cargar aqui los archivos"
                    :value="form.files"
                    @change="updateFiles"
                    @click:clear="form.files = []"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalCreateSupplier"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            @click="sendAddSupplier"
            :color="
              `${currentSupplier ? 'warning' : 'teal accent-4'} text-white`
            "
            small
            class="float-right"
          >
            {{ currentSupplier ? "Actualizar Proveedor" : "Agregar Proveedor" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateSupplier: {
      type: Function,
      default: () => {},
    },
    currentSupplier: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      filesRequiredToUploadSupplier: 5,
      showAllForm: true,
      form: {
        reason_name: null,
        supplier_draft: null,
        supplier_rut: null,
        company_contact: null,
        company_phone: null,
        company_email: null,
        address: null,
        commune: null,
        city: null,
        region: null,
        services: null,
        bank: null,
        type_document_payment: null,
        payment_method: null,
        account_number: null,
        payment_condition: null,
        files: [],
      },
      submitUpload: false,
    };
  },
  validations: {
    form: {
      reason_name: { required },
      supplier_draft: {
        required,
      },
      supplier_rut: {
        required,
      },
      company_contact: {
        required,
      },
      company_phone: {
        required,
      },
      company_email: {
        required,
      },
      address: {
        required,
      },
      commune: {
        required,
      },
      city: {
        required,
      },
      region: {
        required,
      },
      services: {
        required,
      },
      bank: {
        required,
      },
      type_document_payment: {},
      payment_method: {},
      account_number: {
        required,
      },
      payment_condition: {
        required,
      },
      files: {},
    },
  },
  mounted() {
    this.getRegions();
    this.getListOptionsSupplier();
  },
  methods: {
    ...mapActions({
      getRegions: "common/getRegions",
      updateOrCreateSupplier: "supplying/updateOrCreateSupplier",
      getListOptionsSupplier: "supplying/getListOptionsSupplier",
    }),
    updateFiles(newFiles) {
      if (newFiles !== this.form.files) {
        this.form.files = [...this.form.files, ...newFiles];
      }
    },
    async sendAddSupplier() {
      let self = this;
      self.submitUpload = true;
      if (!self.showAllForm || !self.$v.form.$invalid) {
        if (
          !self.form.reason_name ||
          !self.form.supplier_rut ||
          !self.form.supplier_draft
        ) {
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: "Debe completar todos los campos",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
        }
        self.loading = true;
        const resp = await self.updateOrCreateSupplier({
          id: self.currentSupplier ? self.currentSupplier.id : undefined,
          completed: self.showAllForm,
          ...self.form,
          [self.currentSupplier ? "official_name" : "name"]: self.form
            .reason_name,
          rut: self.form.supplier_rut,
          business_category_uuid: self.form.supplier_draft,
          contact_user_name: self.form.company_contact,
          contact_user_email: self.form.company_email,
          contact_phone: self.form.company_phone,
          service_bought: self.form.services,
          bank_uuid: self.form.bank,
          boleta_factura: self.form.type_document_payment,
          user: self.user.email,
        });
        if (resp?.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: self.currentSupplier
              ? "Proveedor modificado correctamente"
              : "Código creado correctamente, sera notificado una vez se haya creado el proveedor",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("supplierCreated");
          this.closeModalCreateSupplier(true);
          this.resetForm();
        }
        if (!resp?.status || resp?.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp
              ? resp.data.detail
              : "Ha ocurrido un error de servidor, intentalo nuevamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalCreateSupplier($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalCreateSupplier($event);
      }
    },
    setSupplier(supplier) {
      this.form = {
        reason_name: supplier.official_name,
        supplier_draft: supplier.business_category
          ? supplier.business_category.id
          : null,
        supplier_rut: supplier.rut,
        company_contact: supplier.contact_user_name,
        company_phone: supplier.contact_phone,
        company_email: supplier.contact_user_email,
        address: supplier.address,
        commune: supplier.commune,
        city: supplier.city,
        region: supplier.region,
        services: supplier.service_bought,
        bank: supplier.bank ? supplier.bank.id : null,
        type_document_payment: supplier.boleta_factura,
        payment_method: supplier.payment_method,
        account_number: supplier.account_number,
        payment_condition: supplier.payment_condition,
        files: [],
      };
    },
    resetForm() {
      this.form = {
        reason_name: null,
        supplier_draft: null,
        supplier_rut: null,
        company_contact: null,
        company_phone: null,
        company_email: null,
        address: null,
        commune: null,
        city: null,
        region: null,
        services: null,
        bank: null,
        type_document_payment: null,
        payment_method: null,
        account_number: null,
        payment_condition: null,
        files: [],
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      allSupplierDrafts: "supplying/allSupplierDrafts",
      allBanks: "supplying/allBanks",
      allTypesDocumentPayment: "supplying/allTypesDocumentPayment",
      allPaymentMethods: "supplying/allPaymentMethods",
      allPaymentConditions: "supplying/allPaymentConditions",
      allRegions: "common/allRegions",
    }),
    wrappedRegions() {
      return this.allRegions.map((item) => item.region);
    },
    communes() {
      if (this.form.region) {
        return this.allRegions.find((item) => item.region == this.form.region)
          .comunas;
      }
      return [];
    },
  },
  watch: {
    currentSupplier(newForm) {
      if (newForm) {
        this.setSupplier(newForm);
      } else {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
