<template>
  <v-container class="p-3 mt-5">
    <loading-flux :value="loadingAll"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        {{ title }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="start" align-md="start" class="mt-1">
      <v-col cols="12" md="4" sm="12">
        <v-btn
          v-if="showActionCreate"
          @click="openModalUpdateOrCreateSupplier(null)"
          color="teal accent-4 text-white"
          small
        >
          <v-icon small class="mr-1">
            mdi-plus
          </v-icon>
          Agregar nuevo proveedor
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-checkbox
          @click="getSuppliersData(1)"
          v-model="filters.only_pending"
          class="mt-4"
        >
          <template v-slot:label>
            <h6 class="mt-2 mx-1 mb-0">
              Proveedores pendientes
            </h6>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-checkbox
          @click="getSuppliersData(1)"
          v-model="filters.only_completed"
          class="mt-4"
        >
          <template v-slot:label>
            <h6 class="mt-2 mx-1 mb-0">
              Proveedores ya creados
            </h6>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pt-0">
        <v-text-field
          v-model="filters.filter"
          @input="getSuppliersData(1)"
          class="mt-5"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="mt-1">
        <v-btn
          class="float-right mt-5"
          color="blue darken-2 text-white"
          small
          @click="downloadExcelSuppliers"
        >
          <v-icon small class="mr-1">
            mdi-export
          </v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <p
      class="text-info mt-5"
      v-if="ifAnyRequestHaveWarning && !currentUserBelongsToSupplying"
    >
      <v-icon class="mr-1" small color="blue darken-2">
        mdi-alert-circle
      </v-icon>
      Una vez que hayas corregido los errores de la solicitud, recuerda marcarla
      como corregida con la acción <b>Marcar solicitud como revisada</b>
    </p>
    <v-data-table
      class="elevation-3 px-3 mb-2 mt-2"
      :headers="wrappedHeaders"
      :items="wrappedSuppliers"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando proveedores"
      item-key="id"
      hide-default-footer
      no-data-text="Sin Proveedores"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.official_name="{ item }">
        <span class="mt-2 text-left" style="font-size: 11px;">
          {{ item.official_name }}
        </span>
      </template>
      <template v-slot:item.business_category="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.business_category ? item.business_category.name : "-" }}
        </span>
      </template>
      <template v-slot:item.rut="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.rut }}
        </span>
      </template>
      <template v-slot:item.approval_request_user="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{
            item.approval_request_user
              ? item.approval_request_user.email
              : item.request_user.email
          }}
        </span>
      </template>
      <template v-slot:item.contact_user_name="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.contact_user_name ? item.contact_user_name : "-" }}
        </span>
      </template>
      <template v-slot:item.contact_phone="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.contact_phone ? item.contact_phone : "-" }}
        </span>
      </template>
      <template v-slot:item.contact_user_email="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.contact_user_email ? item.contact_user_email : "-" }}
        </span>
      </template>
      <template v-slot:item.commune="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.commune ? item.commune : "-" }}
        </span>
      </template>
      <template v-slot:item.city="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.city ? item.city : "-" }}
        </span>
      </template>
      <template v-slot:item.region="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.region ? item.region : "-" }}
        </span>
      </template>
      <template v-slot:item.service_bought="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.service_bought ? item.service_bought : "-" }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex justify-content-center">
          <v-checkbox
            :disabled="true"
            :input-value="item.status === statusSupplier.created ? true : false"
          >
          </v-checkbox>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top v-if="item.rejected">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" class="mr-2" v-bind="attrs" v-on="on">
                mdi-cancel
              </v-icon>
            </template>
            <span>
              {{ item.comments[item.comments.length - 1].comment }}
            </span>
          </v-tooltip>
          <v-tooltip top v-if="item.warning">
            <template v-slot:activator="{ on, attrs }">
              <span
                style="font-size: 1.5rem;"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                ⚠️
              </span>
            </template>
            <span
              >El equipo de Abastecimiento ha indicado que la solicitud tiene
              algunos errores que corregir, revisa la sección de comentarios de
              la solicitud para chequearlos</span
            >
          </v-tooltip>
          <v-menu :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="blue darken-3" fab x-small outlined>
                <v-icon color="white">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list rounded>
              <v-list-item
                class="mb-0"
                v-if="
                  (item.status == statusSupplier.review ||
                    item.status == statusSupplier.pending_review) &&
                    currentUserBelongsToSupplying &&
                    !item.rejected
                "
              >
                <v-list-item-title
                  @click="updateStatusCheckedSupplier(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2" color="success">
                    mdi-check
                  </v-icon>
                  Marcar proveedor como creado
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="mb-0"
                v-if="
                  (item.status == statusSupplier.review ||
                    item.status == statusSupplier.pending_review) &&
                    currentUserBelongsToSupplying &&
                    !item.rejected
                "
              >
                <v-list-item-title
                  @click="rejectSupplier(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2" color="red">
                    mdi-cancel
                  </v-icon>
                  Cancelar Solicitud
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="mb-0"
                v-if="
                  (item.status == statusSupplier.review ||
                    item.status == statusSupplier.pending_review) &&
                    currentUserBelongsToSupplying &&
                    !item.rejected &&
                    !item.warning
                "
              >
                <v-list-item-title
                  @click="updateStatusReviewSupplier(item, true)"
                  x-small
                  class="pointer"
                >
                  <span class="mr-2" style="font-size: 1.1rem">
                    ⚠️
                  </span>
                  Enviar solicitud a revisión
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="mb-0"
                v-if="
                  (item.status == statusSupplier.review ||
                    item.status == statusSupplier.pending_review) &&
                    !item.rejected &&
                    item.warning
                "
              >
                <v-list-item-title
                  @click="updateStatusReviewSupplier(item, false)"
                  x-small
                  class="pointer"
                >
                  <span class="mr-2" style="font-size: 1.1rem">
                    👌
                  </span>
                  Marcar solicitud como revisada
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="mb-0"
                v-if="
                  item.status !== statusSupplier.created &&
                    (currentUserBelongsToSupplying ||
                      (item.approval_request_user &&
                        item.approval_request_user.email === user.email)) &&
                    !item.rejected
                "
              >
                <v-list-item-title
                  @click="openModalUpdateOrCreateSupplier(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon class="mr-2" color="warning">
                    mdi-pencil
                  </v-icon>
                  Editar
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="mb-0 mt-0">
                <v-list-item-title
                  @click="openModalCommentsSupplier(item)"
                  x-small
                  class="pointer"
                >
                  <v-badge
                    :content="item.comments.length ? item.comments.length : '-'"
                    color="success"
                    overlap
                  >
                    <v-icon class="mr-2" color="primary">
                      mdi-comment-outline
                    </v-icon>
                  </v-badge>
                  <span class="mx-2">
                    Comentarios
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  icon
                  @click="openModalFilesSupplier(item)"
                  color="primary"
                  x-small
                  class="pointer"
                >
                  <v-badge
                    bordered
                    color="green"
                    :content="
                      item.documents.length ? item.documents.length : '-'
                    "
                    overlap
                  >
                    <v-icon class="">
                      mdi-file-multiple-outline
                    </v-icon>
                  </v-badge>
                  <span class="mx-3">
                    Ver archivos cargados
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="mb-0">
                <v-list-item-title
                  @click="openModalPaymentInfoSupplier(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon
                    class="mr-2"
                    :color="item.bank ? 'primary' : 'warning'"
                  >
                    mdi-bank
                  </v-icon>
                  <span class="mx-1">
                    Ver información bancaria
                    {{ `${item.bank ? "" : "(PENDIENTE)"}` }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  @click="openModalContactInfoSupplier(item)"
                  x-small
                  class="pointer"
                >
                  <v-icon
                    class="mr-2"
                    :color="item.contact_user_name ? 'primary' : 'warning'"
                  >
                    mdi-account
                  </v-icon>
                  <span class="mx-2"
                    >Ver información del contacto de la empresa
                    {{ `${item.contact_user_name ? "" : "(PENDIENTE)"}` }}</span
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:item.request_approval_date="{ item }">
        <span style="font-size: 11px;">
          {{ moment(item.created_at).format("DD/MM/YYYY HH:mm [hrs]") }}
        </span>
      </template>
      <template v-slot:item.sap_date="{ item }">
        <span style="font-size: 11px;">
          {{
            item.sap_date
              ? moment(item.sap_date).format("DD/MM/YYYY HH:mm [hrs]")
              : "-"
          }}
        </span>
      </template>
      <template v-slot:item.time_creation="{ item }">
        <span style="font-size: 11px;">
          {{ getTimeCreationDate(item) }}
        </span>
      </template>
    </v-data-table>
    <div class="float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="suppliers.number_of_pages ? suppliers.number_of_pages : 1"
      ></v-pagination>
    </div>
    <modal-create-supplier
      :modalAction="dialogCreateSupplier"
      :closeModalCreateSupplier="closeModalUpdateOrCreateSupplier"
      :currentSupplier="currentSupplier"
      @supplierCreated="getSuppliersData"
    >
    </modal-create-supplier>
    <modal-files-supplier
      :modalAction="dialogFilesSupplier"
      :closeModalFilesSupplier="closeModalFilesSupplier"
      :currentSupplier="currentSupplierShowFiles"
      :showDeleteFileAndUpload="currentUserBelongsToSupplying"
      @filesUploaded="updateFilesCurrentSuplier"
    >
    </modal-files-supplier>
    <modal-payment-info-supplier
      :modalAction="dialogPaymentInfoSupplier"
      :closeModalPaymentInfoSupplier="closeModalPaymentInfoSupplier"
      :currentSupplier="currentSupplierPaymentInfo"
    >
    </modal-payment-info-supplier>
    <modal-contact-info-supplier
      :modalAction="dialogContactInfoSupplier"
      :closeModalContactInfoSupplier="closeModalContactInfoSupplier"
      :currentSupplier="currentSupplierContactInfo"
    >
    </modal-contact-info-supplier>
    <modal-comments
      :modalAction="dialogCommentsSupplier"
      :closeModalComments="closeModalCommentsSupplier"
      :currentItem="currentSupplierComments"
      @commentAdded="getSuppliersData"
      @commentDeleted="getSuppliers"
      commentType="PROVEEDOR"
    >
    </modal-comments>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import moment from "moment";
import ModalCreateSupplier from "./components/ModalCreateSupplier.vue";
import ModalFilesSupplier from "./components/ModalFilesSupplier.vue";
import ModalPaymentInfoSupplier from "./components/ModalPaymentInfoSupplier.vue";
import ModalContactInfoSupplier from "./components/ModalContactInfoSupplier.vue";
import ModalComments from "@/components/commons/form/ModalComments.vue";
import { STATUS_SUPPLIER } from "@/constants/supplier";
import { calculateDiferenceTwoDates } from "@/helpers/common";

export default {
  components: {
    ModalFilesSupplier,
    ModalCreateSupplier,
    ModalPaymentInfoSupplier,
    ModalContactInfoSupplier,
    ModalComments,
  },
  props: {
    showActionCreate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Formulario de creación de proveedores",
    },
  },
  mounted() {
    this.getSuppliersData();
  },
  beforeDestroy() {
    this.clearSuppliers();
  },

  data() {
    return {
      moment,
      loading: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        filter: "",
        only_completed: false,
        only_pending: false,
      },
      statusSupplier: STATUS_SUPPLIER,
      dialogCreateSupplier: false,
      dialogFilesSupplier: false,
      dialogPaymentInfoSupplier: false,
      dialogContactInfoSupplier: false,
      dialogCommentsSupplier: false,
      pageCount: 0,
      loadingAll: false,
      loadingSuppliers: false,
      currentSupplier: null,
      currentSupplierShowFiles: null,
      currentSupplierPaymentInfo: null,
      currentSupplierContactInfo: null,
      currentSupplierComments: null,
      currentUserBelongsToSupplying: false,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: this.title,
          disabled: true,
          href: "/supplying/form/suppliers",
        },
      ],
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Razón Social",
          sortable: false,
          width: "12%",
          align: "center",
          value: "official_name",
        },
        {
          text: "Giro",
          sortable: false,
          width: "10%",
          align: "center",
          value: "business_category",
        },
        {
          text: "RUT",
          align: "center",
          value: "rut",
          sortable: false,
          width: "8%",
        },
        {
          text: "Usuario",
          align: "center",
          value: "approval_request_user",
          width: "5%",
          sortable: false,
        },
        {
          text: "Región",
          align: "center",
          value: "region",
          width: "5%",
          sortable: false,
        },
        {
          text: "Comuna",
          align: "center",
          value: "commune",
          width: "8%",
          sortable: false,
        },
        {
          text: "Ciudad",
          align: "center",
          value: "city",
          width: "8%",
          sortable: false,
        },
        {
          text: "Servicios Proveedor",
          align: "center",
          value: "service_bought",
          width: "5%",
          sortable: false,
        },
        {
          text: "Proveedor Creado",
          align: "center",
          value: "status",
          width: "10%",
          sortable: false,
        },
        {
          text: "Fecha solicitud",
          align: "center",
          value: "request_approval_date",
          width: "8%",
          sortable: false,
        },
        {
          text: "Fecha creación",
          align: "center",
          value: "sap_date",
          width: "8%",
          sortable: false,
        },
        {
          text: "Tiempo creación",
          align: "center",
          value: "time_creation",
          width: "8%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      suppliers: "supplying/suppliers",
    }),
    wrappedSuppliers() {
      return this.suppliers.items;
    },
    ifAnyRequestHaveWarning() {
      return this.wrappedSuppliers.find((item) => item.warning);
    },
    wrappedHeaders() {
      return this.tableHeaders;
    },
  },
  methods: {
    ...mapActions({
      getSuppliers: "supplying/getSuppliers",
      sendDownloadExcelSuppliers: "supplying/downloadExcelSupplier",
      updateStatusSupplier: "supplying/updateStatusSupplier",
      verifyUserBelongsToSupplying: "supplying/verifyUserBelongsToSupplying",
      clearSuppliers: "supplying/clearSuppliers",
      sendUpdateStatusReviewSupplier: "supplying/updateStatusReviewForm",
      sendRejectSupplier: "supplying/rejectForm",
    }),
    getTimeCreationDate(item) {
      if (item.sap_date && item.created_at) {
        return calculateDiferenceTwoDates(item.sap_date, item.created_at);
      }
      return "-";
    },
    async updateFilesCurrentSuplier(currentSupplier) {
      await this.getSuppliersData();
      setTimeout(() => {
        let supplierUpdated = this.suppliers.items.find(
          (item) => item.id == currentSupplier.id
        );
        this.currentSupplierShowFiles = supplierUpdated;
      }, 1000);
    },
    async getVerifyUserBelongsToSupplying(email) {
      const response = await this.verifyUserBelongsToSupplying({
        email,
      });
      if (response.is_abastecimiento) {
        this.currentUserBelongsToSupplying = response.is_abastecimiento;
      }
    },
    async updateStatusCheckedSupplier(supplier) {
      this.$swal
        .fire({
          icon: "info",
          title: `¿Estas seguro de marcar este proveedor: ${supplier.official_name} como creado`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#1565C0",
          confirmButtonText: "Aceptar",
          cancelButtonText: `Cancelar`,
          input: "text",
          inputPlaceholder: "Ingresa el código SAP del Proveedor",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el código de SAP"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingAll = true;
            let payload = {
              id: supplier.id,
              user: this.user.email,
              card_code: result.value,
            };
            const resp = await this.updateStatusSupplier(payload);

            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Proveedor marcado como creado correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getSuppliersData();
            }
            this.loadingAll = false;
          }
        });
    },
    async updateStatusReviewSupplier(material, warning) {
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estas seguro de ${
            warning ? "enviar esta solicitud" : "marcar esta solicitud"
          }: <b>${material.official_name}</b>  ${
            warning ? "a Revisión" : "Revisada"
          } ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "warning",
          confirmButtonText: `${warning ? "Enviar" : "Marcar"}`,
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingAll = true;
            let payload = {
              request_id: material.id,
              warning: warning ? true : false,
              resolved: !warning ? true : false,
              rejected: false,
              comment: result.value,
              user: this.user.email,
              comment_type: "PROVEEDOR",
            };
            const resp = await this.sendUpdateStatusReviewSupplier(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud enviada a revisión correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getSuppliersData();
            }
            this.loadingAll = false;
          }
        });
    },
    async rejectSupplier(supplier) {
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estas seguro de Cancelar esta solicitud <b>${supplier.official_name}</b> ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: `Cancelar Solicitud`,
          cancelButtonText: `Cerrar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder:
            "Ingresa el motivo de cancelación para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el motivo de cancelación"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingAll = true;
            let payload = {
              request_id: supplier.id,
              comment: result.value,
              user: this.user.email,
              comment_type: "PROVEEDOR",
            };
            const resp = await this.sendRejectSupplier(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud cancelada correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getSuppliersData();
            }
            this.loadingAll = false;
          }
        });
    },
    openModalCommentsSupplier(supplier) {
      this.currentSupplierComments = supplier;
      this.dialogCommentsSupplier = true;
    },
    closeModalCommentsSupplier() {
      this.currentSupplierComments = null;
      this.dialogCommentsSupplier = false;
    },
    openModalUpdateOrCreateSupplier(supplier = null) {
      this.currentSupplier = supplier;
      this.dialogCreateSupplier = true;
    },
    closeModalUpdateOrCreateSupplier() {
      this.currentSupplier = null;
      this.dialogCreateSupplier = false;
    },
    openModalFilesSupplier(supplier) {
      this.currentSupplierShowFiles = supplier;
      this.dialogFilesSupplier = true;
    },
    closeModalFilesSupplier() {
      this.currentSupplierShowFiles = null;
      this.dialogFilesSupplier = false;
    },
    openModalPaymentInfoSupplier(supplier) {
      this.currentSupplierPaymentInfo = supplier;
      this.dialogPaymentInfoSupplier = true;
    },
    closeModalPaymentInfoSupplier() {
      this.currentSupplierPaymentInfo = null;
      this.dialogPaymentInfoSupplier = false;
    },
    openModalContactInfoSupplier(supplier) {
      this.currentSupplierContactInfo = supplier;
      this.dialogContactInfoSupplier = true;
    },
    closeModalContactInfoSupplier() {
      this.currentSupplierContactInfo = null;
      this.dialogContactInfoSupplier = false;
    },
    async getSuppliersData(page = 1) {
      let self = this;
      clearTimeout(self.debounce);
      self.debounce = setTimeout(async () => {
        self.loading = true;
        await self.getSuppliers({
          page,
          user: self.user.email,
          filter: self.filters.filter ? self.filters.filter : undefined,
          status_filter: self.filters.only_completed
            ? self.statusSupplier.created
            : undefined,
          in_sap: self.filters.only_pending ? false : undefined,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    async downloadExcelSuppliers() {
      this.loadingAll = true;
      const response = await this.sendDownloadExcelSuppliers({
        user: this.user.email,
        filter: this.filters.filter ? this.filters.filter : undefined,
        status_filter: this.filters.only_completed
          ? this.statusSupplier.created
          : undefined,
        in_sap: this.filters.only_pending ? false : undefined,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `códigos_proveedores.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loadingAll = false;
    },
  },
  watch: {
    "filters.page": {
      handler: function(page) {
        console.log(page);
        this.getSuppliersData(page);
      },
    },
    user: {
      immediate: true,
      handler(user) {
        if (user) {
          this.getVerifyUserBelongsToSupplying(this.user.email);
        }
      },
    },
  },
};
</script>

<style></style>
